

.greenlight {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.greenlight .inputs{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin-top: 40px;
    margin-bottom: 40px;;
}

.tb-cover {
    width: 100%;
    max-width: 600px;
}

.restartWarning {
    color: red;
    font-size: 28px;
}
